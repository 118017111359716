import * as React from 'react';
import AmplifiedLogo from '../images/svg/amplified-logo.svg';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

export default function Navigation() {
  const { i18n } = useTranslation();

  return (
    <Popover as="header" className="relative">
      <div className="pt-6">
        <nav
          className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
          aria-label="Global"
        >
          <div className="flex items-center flex-1">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link to="/">
                <span className="sr-only">Amplified</span>
                <AmplifiedLogo className="h-8 w-auto sm:h-10" />
              </Link>
              <div className="-mr-2 flex items-center md:hidden">
                <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                  <span className="sr-only">
                    <Trans>Open main menu</Trans>
                  </span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>
          <div className="hidden md:flex md:items-center md:space-x-6">
            <div className="hidden space-x-8 md:flex md:ml-10">
              <Link
                to="/how-it-works"
                className="text-base font-display font-medium text-chocolate"
              >
                <Trans>How it works</Trans>
              </Link>
              <Link
                to="/blog"
                className="text-base font-display font-medium text-chocolate"
              >
                <Trans>Blog</Trans>
              </Link>
              <Link
                to="/pricing"
                className="text-base font-display font-medium text-chocolate"
              >
                <Trans>Pricing</Trans>
              </Link>
              <a
                href={
                  i18n.language === 'en'
                    ? 'https://support.amplified.ai'
                    : 'https://ja.support.amplified.ai'
                }
                className="text-base font-display font-medium text-chocolate"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Trans>Resources</Trans>
              </a>
            </div>
            <a
              href="https://app.amplified.ai/login"
              className="font-display text-base font-medium text-chocolate"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Log in</Trans>
            </a>
            <a
              href="https://app.amplified.ai/signup"
              className="font-display inline-flex items-center px-8 py-2 border border-transparent text-base font-medium rounded-full text-white bg-pastel_red hover:bg-pastel_red_120"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Sign up</Trans>
            </a>
          </div>
        </nav>
      </div>

      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top md:hidden"
        >
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600">
                  <span className="sr-only">
                    <Trans>Close menu</Trans>
                  </span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="pt-5 pb-6">
              <div className="px-2 space-y-1 text-center">
                <Link
                  to="/how-it-works"
                  className="block px-3 py-2 rounded-md
                  text-base font-medium font-display text-chocolate"
                >
                  <Trans>How it works</Trans>
                </Link>
                <Link
                  to="/blog"
                  className="block px-3 py-2 rounded-md text-base font-medium font-display text-chocolate"
                >
                  <Trans>Blog</Trans>
                </Link>
                <Link
                  to="/pricing"
                  className="block px-3 py-2 rounded-md text-base font-medium font-display text-chocolate"
                >
                  <Trans>Pricing</Trans>
                </Link>
                <a
                  href={
                    i18n.language === 'en'
                      ? 'https://support.amplified.ai'
                      : 'https://ja.support.amplified.ai'
                  }
                  className="block px-3 py-2 rounded-md text-base font-medium font-display text-chocolate"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>Resources</Trans>
                </a>
              </div>
              <div className="w-full text-center mt-6 px-5">
                <a
                  href="https://app.amplified.ai/signup"
                  className="text-center inline-flex items-center px-8 py-2 border border-transparent text-base font-medium rounded-full text-white bg-pastel_red hover:bg-pastel_red_120"
                >
                  <Trans>Sign up</Trans>
                </a>
              </div>
              <div className="mt-6 px-5">
                <p className="text-center text-base font-medium text-gray-500">
                  <Trans>Existing customer?</Trans>{' '}
                  <a
                    href="https://app.amplified.ai/login"
                    className="text-gray-900 hover:underline"
                  >
                    <Trans>Log in</Trans>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
