import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../components/footer';
import Navigation from '../components/navigation';
import SEO from './seo';

export default function Layout({
  title,
  description,
  article,
  image,
  children,
}) {
  return (
    <div className="min-h-screen">
      <SEO
        title={title}
        description={description}
        article={article}
        image={image}
      />
      <div className="relative overflow-hidden">
        <Navigation />
        <main>{children}</main>
        <Footer />
      </div>
    </div>
  );
}

Layout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  article: PropTypes.bool,
  children: PropTypes.node,
};

Layout.defaultProps = {
  title: null,
  description: null,
  article: false,
  children: null,
};
